.component {
  position: relative;
  height: 100%;
  width: 100%;
}

.showCol {
  position: relative;
  height: 100%;
}

.table {
  position: relative;
  height: 100%;
  width: 100%;
  padding: 5px;
  display: flex;
  flex-direction: column;
}

.table::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background: #707071;
  mix-blend-mode: multiply;
  z-index: -2;
}

.table::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background: rgb(173, 173, 173);
  mix-blend-mode: multiply;
  z-index: -1;
}

.tableRow,
.tableHeader {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
}

.tableHeader {
  margin-bottom: 5px;
}

.headerElem {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: 0;
}

.headerElem::before {
  content: "";
  position: absolute;
  height: 100%;
  width: calc(100% - 1px);
  background-color: rgb(117, 173, 173);
  top: 0px;
  left: 1px;
  z-index: -1;
}

.tableRow {
  color: white;
}

.tableHeader {
  color: black;
}

.tableCol,
.headerElem {
  font-size: 1.25rem;
  text-align: center;
}

.tableCol {
  padding: 7px 10px;
  border-left: #474747 1px solid;
}

.tableCol:first-child {
  border: none;
}

.showItem {
  background: linear-gradient(0.85turn, #252c39, #3c485e);
  border-bottom: #474747 1px solid;
}

.bodyFont {
  font-family: var(--body-font);
}

.altFont {
  font-family: var(--alternate-font);
  font-size: 1.15rem;
}
