.component {
  position: relative;
  height: 100%;
  width: 100%;
  height: calc(100vh - var(--menu-height));
}

.component::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 100%;
  background: linear-gradient(0.15turn, rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.6));
}

.redBar {
  width: 100%;
  height: 5px;
  background: linear-gradient(0.85turn, var(--vice-blue), var(--vice-pink));
}

.grid {
  position: relative;
  width: 100%;
  height: 100%;
}

.leftCol {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 200px;
  height: 100%;
  margin-top: 40px;
}

.rightCol {
  position: absolute;
  top: 0px;
  left: 200px;
  height: calc(100vh - var(--menu-height) - 90px);
  width: calc(100% - 200px);
  margin-top: 40px;
}

.menu {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.menu::before {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  height: calc(100vh - var(--menu-height) - 90px);
  width: 100%;
  border-left: black 1px solid;
}

.selection {
  position: relative;
  color: black;
  border-radius: 3px;
  margin: 0px 10px;
}

.selection > * {
  font-family: var(--header-font);
  width: 100%;
  font-size: 1.25rem;
  background: none;
  border: none;
  text-align: left;
  padding: 7px 5px 7px 8px;
  margin: 0px;
}

.rightBox {
  position: relative;
  /* margin: 10px; */
  height: 100%;
  width: 100%;
}
