.component {
  position: relative;
  font-family: var(--alternate-font);
  font-size: 1.05rem;
  color: white;
  height: 100%;
  width: 100%;
}

.component::before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  background: lightgray;
  mix-blend-mode: multiply;
  z-index: -1;
}

.padding {
  padding: 20px;
  height: 100%;
  width: 100%;
}

.item {
  margin-bottom: 20px;
}

.bold {
  font-weight: 550;
}

.manageInput {
  position: relative;
  display: inline;
  height: 100%;
  width: 100%;
  padding: 7px 0px;
}

.manageInput::before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  border-radius: 5px;

  background: rgb(173, 173, 173);
  mix-blend-mode: multiply;
  z-index: -1;
}

.btn {
  width: 200px;
  font: inherit;
  line-height: 1.15rem;
  padding: 5px 7px;
  background: var(--primary-color);
  border: none;
  color: white;
}

.btn:hover {
  opacity: 0.9;
  cursor: pointer;
}

.btn:active {
  transform: translateX(1px) translateY(2px);
  border: var(--selected-color) 1px solid;
}
