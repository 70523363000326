.component {
  position: relative;
  height: 100%;
  width: 100%;
}

.row {
  display: flex;
  flex-direction: row;
}

.leftCol {
  margin-right: 10px;
}

.rightCol {
  height: 100%;
  width: 650px;
  opacity: 0.8;
  margin-left: 40px;
}
