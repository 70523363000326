.component {
  position: relative;
  height: calc(100vh - var(--menu-height));
  overflow-y: hidden;
  background: linear-gradient(0.15turn, rgb(0, 0, 0, 0.2), rgb(0, 0, 0, 0.6));
}

.centerCol {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.yLevel {
  position: absolute;
  top: 15%;
}

.grid {
  position: relative;
  max-width: 1200px;
  width: 100%;
  height: 100%;
}

.leftCol {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 60%;
  height: 100%;
}

.rightCol {
  position: absolute;
  top: 0px;
  left: 60%;
  width: 40%;
  height: 100%;
}

@media screen and (max-width: 1080px) {
  .leftCol {
    width: 50%;
  }

  .rightCol {
    width: 50%;
    left: 50%;
  }
}

@media screen and (max-width: 850px) {
  .leftCol {
    visibility: collapse;
  }
  .rightCol {
    width: 100%;
    left: 0%;
  }
}

.graphic {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  height: 200px;
}

.logo img {
  height: 100%;
}

.blackLine {
  background: black;
  height: 2px;
  width: 50%;
  margin: 20px 0px;
}

.description {
  font-family: var(--body-font);
  font-size: 2rem;
  font-weight: bold;
  margin: 18px;
}

.box {
  background: rgb(189, 189, 189);
  width: 100%;
  max-width: 350px;
  border: solid 1px var(--primary-color);
  border-radius: 5px;
}

.loginInput {
  width: calc(100% - 20px);
  margin: 10px 10px 0px 10px;
  line-height: 2rem;
  font-family: var(--alternate-font);
  font-size: 1.2rem;
  background: var(--white-color);
  border: solid 1px rgb(41, 41, 41);
  border-radius: 5px;
  padding: 7px 13px;
}

.button {
  font-family: var(--alternate-font);
  height: 46px;
  font-size: 1.2rem;

  width: calc(100% - 20px);
  padding: 5px;
  border-radius: 0px;
  background: var(--primary-color);
  color: white;
  border: none;
  margin: 10px;
}

.button:hover {
  cursor: pointer;
  opacity: 0.9;
}

.greyLine {
  background: black;
  height: 1px;
  width: 75%;
  margin: 10px auto 30px auto;
}

.personnel {
  margin: 10px auto;
  font-family: var(--body-font);
  font-weight: bold;
  font-size: 2rem;
}

.personnel p {
  text-align: center;
}

.error {
  margin: 10px 10px 7px 10px;
  color: red;
  width: 100%;
}
