.col {
  display: inline-block;
  height: 100%;
  width: 790px;
  overflow-y: auto;
}

.col::-webkit-scrollbar {
  background: grey;
  width: 10px;
  border-radius: 2px;
}

/* Handle */
.col::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 2px;
}

/* Handle on hover */
.col::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 70, 70);
}

.videoGrid {
  margin-top: 20px;
}

.item {
  margin-bottom: 10px;
}

.row {
  display: flex;
  flex-direction: row;
}

.video {
  width: 300px;
}

.deleteIcon {
  position: relative;
  height: 20px;
  width: 100%;
}

.deleteIcon::before {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  top: 50%;
  height: 2px;
  width: 20px;
  background-color: var(--white);
  transform: rotate(45deg);
}

.deleteIcon::after {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  top: 50%;
  height: 2px;
  width: 20px;
  background-color: var(--white);
  transform: rotate(-45deg);
}

.inputSection {
  height: 129px;
}

.deleteBtn {
  position: absolute;
  top: 0px;
  left: calc(50% - 10px);
  padding: 0px;
  margin: 0px;
  height: 20px;
  width: 20px;
  opacity: 0;
  z-index: 1;
}

.deleteBtn:hover {
  cursor: pointer;
}

.checkboxItem {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  margin-top: 5px;
}

.customCheckbox {
  display: inline-block;
  position: relative;
  height: 22px;
  width: 22px;
  background-color: var(--selected-color);
  margin: 0px 10px 0px 0px;
  border-radius: 2px;
  padding: 0px;
  border: black 1px solid;
}

.checkbox {
  position: absolute;
  height: 20px;
  width: 20px;
  opacity: 0;
  padding: 0px;
}

.customCheckbox:hover {
  cursor: pointer;
}

.check {
  position: absolute;
  display: inline-block;
  left: 1.7px;
  height: 20px;
  color: black;
}

.highlightText {
  line-height: 22px;
}

.tableHeader {
  display: flex;
  flex-direction: row;
}

.headerVideo {
  width: 300px;
}

.headerId,
.headerHighlight,
.headerDelete {
  width: 50%;
}

.headerVideo,
.headerId,
.headerHighlight,
.headerDelete {
  text-align: center;
  position: relative;
  padding: 3px 0px;
  margin-bottom: 10px;
}

.headerVideo::before,
.headerId::before,
.headerHighlight::before,
.headerDelete::before {
  position: absolute;
  content: "";
  left: 1%;
  top: 0px;
  width: 98%;
  height: 100%;
  background: white;
  z-index: -1;
  padding: 3px 0px;
  mix-blend-mode: difference;
}

.id,
.highlight,
.delete {
  position: relative;
  padding: 5px 0px;
  font-family: var(--alternate-font);
  width: calc(50% - 4px);
  margin: 0px 2px;
  text-align: center;
}

.id::before,
.highlight::before,
.delete::before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgb(46, 45, 45);
  mix-blend-mode: difference;
  z-index: -1;
}
