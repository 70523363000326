.component {
  position: relative;
}

.gold {
  background: goldenrod;
  height: 100%;
  width: 100%;
}

.content {
  width: 100%;
  height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.followText {
  position: relative;
  width: 100%;
}

.followText div {
  position: absolute;
  top: -50px;
  font-family: var(--body-font);
  font-size: 2rem;
  font-weight: bold;
  width: 100%;
  text-align: center;
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.followText p {
  display: inline;
  margin: 0px 20px;
}

.component ul {
  margin-top: 40px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  width: 75%;
}

.component li {
  list-style: none;
}

.item {
  position: relative;
  height: 60px;
}

.item img {
  height: 100%;
}

.item a {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}

@media screen and (max-width: 450px) {
  .content {
    height: auto;
  }

  .followText div {
    position: relative;
    flex-direction: column;
    top: 0;
  }
  .component ul {
    flex-direction: column;
    margin-top: 0px;
  }

  .component ul li {
    position: relative;
  }

  .component ul li::before {
    content: "";
    height: 100%;
    width: 90%;
    top: 0;
    left: 5%;
    position: absolute;

    border-bottom: solid 1px rgb(44, 44, 44);
  }

  .item {
    height: 80px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin: 30px 0px;
  }
}
