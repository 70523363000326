.component {
  position: relative;
  height: 100%;
  width: 100%;
}

.link,
.logo {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
}
