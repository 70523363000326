.component {
  position: relative;
}

.col {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.title {
  margin-bottom: 20px;
  width: 100%;
  text-align: center;
  font-family: var(--body-font);
  font-weight: lighter;
  font-size: 2.3rem;
}

.bar {
  height: 2px;
  width: 70px;
  background: var(--primary-color);
}
