.component {
  position: relative;
  height: 100%;
  width: 100%;

  overflow-y: hidden;
  overflow-x: hidden;
  box-shadow: 0px 2px 6px 0px black;
}

.component img {
  position: absolute;
}
