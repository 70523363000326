@font-face {
  font-family: "ClaxtonBold";
  src: local("ClaxtonBold"),
    url("../assets/fonts/Claxton-Bold.otf") format("opentype");
  font-weight: bold;
}

@font-face {
  font-family: "ClaxtonLight";
  src: local("ClaxtonLight"),
    url("../assets/fonts/Claxton-Light.otf") format("opentype");
  font-weight: lighter;
}

@font-face {
  font-family: "Claxton";
  src: local("Claxton"), url("../assets/fonts/Claxton.otf") format("opentype");
}

:root {
  --header-font-bold: "ClaxtonBold";
  --header-font: "Claxton";
  --body-font: "ClaxtonLight";
  /* --cursive-font: "Pacifico", cursive; */
  --cursive-font: "Merienda", cursive;
  /* --cursive-font: "Permanent Marker", cursive; */
  /* --cursive-font: "Satisfy", cursive; */

  --alternate-font: "Karla", sans-serif;

  --menu-font: "Slabo 27px", serif;

  --menu-height: 60px;

  --primary-color: #1c2434;
  --color: #27324b;
  --primary-gradient: linear-gradient(0.85turn, #1c2435e0, #090c11d5);
  --selected-color: #66ffff;
  --spotify-green: #1db954;
  --gold-gradient: linear-gradient(0.2turn, gold, 3%, goldenrod, 97%, gold);

  --menu-speed: 0.75s;

  /* --white-color: #1c2434; */
  --white-color: rgb(248, 248, 244);
  /* --white-color: rgb(102, 102, 105); */
  --bwite-color: rgb(208, 208, 214);

  --website-width: 1480px;

  --white: #f4f4f4;
  --black: #000;

  --grey-gradient: linear-gradient(
    0.15turn,
    rgba(34, 34, 34, 0.9),
    rgba(27, 27, 27, 0.95)
  );
  --desktop-album-width: 260px;
  --mobile-album-width: 160px;

  --vice-blue: #0bd3d3;
  --vice-pink: #f890e7;

  overflow-y: auto;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

@media screen and (max-width: 650px) {
  .newBody {
    padding-top: var(--menu-height);
  }
}

.separation-bar__black {
  background-color: var(--black);
  width: 80px;
  height: 5px;
}

.cursive {
  font-family: var(--cursive-font);
}

.header-font {
  font-family: var(--header-font);
}

.margin-5 {
  margin: 5px;
}

.margin-10 {
  margin: 10px;
}

.margin-top-10 {
  margin-top: 10px;
}

.margin-top-5 {
  margin-top: 5px;
}

.margin-bottom-10 {
  margin-bottom: 10px;
}

.margin-bottom-20 {
  margin-bottom: 20px;
}

.margin-bottom-5 {
  margin-bottom: 5px;
}

.container {
  max-width: var(--website-width);
  margin: auto;
}

/* Space needed to keep the menu from overlapping with other stuff */
.space {
  height: var(--header-height);
  width: 100%;
}

.noSelect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                                  supported by Chrome, Opera and Firefox */
}

.volumeDiv {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/* The slider itself */
.volumeSlider {
  -webkit-appearance: none; /* Override default CSS styles */
  appearance: none;
  width: 100%; /* Full-width */
  height: 5px; /* Specified height */
  background: white; /* Grey background */
  outline: none; /* Remove outline */
  opacity: 0.7; /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s; /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.volumeSlider:hover {
  opacity: 1; /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.volumeSlider::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
}

.volumeSlider::-moz-range-thumb,
.volumeSlider::-webkit-slider-thumb {
  /* border: solid 1px black; */
  width: 15px; /* Set a specific slider handle width */
  height: 15px; /* Slider handle height */
  background: white; /* Green background */
  visibility: collapse;
  border-radius: 50%;
}

.volumeSlider:hover::-moz-range-thumb,
.volumeSlider:hover::-webkit-slider-thumb {
  visibility: visible;
}

@keyframes spin {
  from {
    transform: rotate(360deg);
  }
  to {
    transform: rotate(0deg);
  }
}
