.item {
  margin-bottom: 20px;
}

.col {
  display: inline-block;
  width: 640px;
  height: 100%;
  overflow-y: auto;
}

.col::-webkit-scrollbar {
  background: grey;
  width: 10px;
  border-radius: 2px;
}

/* Handle */
.col::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 2px;
}

/* Handle on hover */
.col::-webkit-scrollbar-thumb:hover {
  background: rgb(70, 70, 70);
}

.inputSection {
  height: 125px;
}

.row,
.headerRow {
  display: flex;
  flex-direction: row;
}

.row {
  margin-bottom: 4px;
}

.header {
  text-align: center;
  min-width: 204px;
  position: relative;
  padding: 3px 0px;
  margin-bottom: 10px;
}

.header::before {
  position: absolute;
  content: "";
  left: 1%;
  top: 0px;
  width: 98%;
  height: 100%;
  background: white;
  z-index: -1;
  padding: 3px 0px;
  mix-blend-mode: difference;
}

.rowElem {
  position: relative;
  padding: 5px 0px;
  font-family: var(--alternate-font);
  width: 200px;
  margin: 0px 2px;
  text-align: center;
  word-wrap: break-word;
}

.rowElem::before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  height: 100%;
  width: 100%;
  background-color: rgb(46, 45, 45);
  mix-blend-mode: difference;
  z-index: -1;
}

.cover {
  width: 146px;
  height: 164px;
}

.coverImg {
  height: 100%;
  width: 100%;
}

.deleteIcon {
  position: relative;
  height: 20px;
  width: 100%;
}

.deleteIcon::before {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  top: 50%;
  height: 2px;
  width: 20px;
  background-color: var(--white);
  transform: rotate(45deg);
}

.deleteIcon::after {
  content: "";
  position: absolute;
  left: calc(50% - 10px);
  top: 50%;
  height: 2px;
  width: 20px;
  background-color: var(--white);
  transform: rotate(-45deg);
}

.deleteBtn {
  position: absolute;
  top: 0px;
  left: calc(50% - 10px);
  padding: 0px;
  margin: 0px;
  height: 20px;
  width: 20px;
  opacity: 0;
  z-index: 1;
}

.deleteBtn:hover {
  cursor: pointer;
}

.textWrapper {
  max-width: 600px;
}
