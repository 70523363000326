.component {
  position: relative;
  width: 100%;
}

.row {
  margin: 0px 30px;
  width: calc(100% - 60px);
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.row ul {
  display: flex;
  flex-direction: row;
  justify-content: center;
  line-height: 1rem;
  width: 100%;
}

.album {
  position: relative;
  overflow: hidden;
  height: 360px;
  width: 360px;
  background: linear-gradient(0.25turn, var(--spotify-green), white);
}

.album::before {
  content: "";
  position: absolute;
  display: block;
  background: radial-gradient(
    rgb(0, 0, 0, 0.85),
    50%,
    rgb(0, 0, 0, 0.7),
    90%,
    rgb(0, 0, 0, 0.6)
  );
  top: 0;
  left: 0;
  opacity: 0;
  width: 100%;
  height: 100%;
  transition: opacity 0.5s;
  z-index: 1;
}

.album:hover:after,
.album:hover:before {
  opacity: 1;
  transition: opacity 0.75s;
}

.album:hover .cover {
  transform: scale(1.2);
}

.album:hover .albumInfo {
  opacity: 1;
  transition: opacity 1s;
}
