.updateBioWrapper {
  position: relative;
  display: inline-block;
  width: 600px;
  height: 400px;
  padding: 7px 0px;
  border-radius: 5px;
}

.updateBioWrapper::before {
  content: "";
  display: inline-block;
  position: absolute;
  height: 100%;
  width: 100%;
  top: 12px;
  left: 0px;
  border-radius: 5px;

  background: rgb(173, 173, 173);
  mix-blend-mode: multiply;
  z-index: -1;
}

.updateBio {
  position: absolute;
  resize: none;
  margin: 5px 0px;
  width: 100%;
  height: 100%;
  background: none;
  color: white;
  padding: 5px 7px;
  line-height: 1.15rem;
  font-size: 1.05rem;
  font-family: var(--alternate-font);
  border-radius: 5px;
  border: #222 1px solid;
}

.updateBio:focus {
  outline-style: solid;
  outline-color: var(--selected-color);
}
